import React from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import tw from 'twin.macro'

const PortableTextArticle = ({ blocks }) => (
  <BasePortableText
    className="article"
    tw="mt-10"
    css={{
      display: 'grid',
      gridTemplateColumns: '1fr min(650px, calc(100% - 48px)) 1fr',
      gridColumnGap: 16,
      '& > *': {
        gridColumn: 2,
      },
      'p:first-of-type,h2:first-of-type, blockquote:first-of-type': {
        marginTop: 0,
      },
      '@media (min-width: 1024px)': {
        '& > aside': {
          gridColumn: 3,
          padding: '1.75rem',
          paddingTop: 16,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          maxWidth: '400px',
        },
      },
    }}
    blocks={blocks}
    serializers={serializers}
    {...clientConfig.sanity}
  />
)

export default PortableTextArticle
